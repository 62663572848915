import { useState, useEffect, useRef } from 'react';

const Computer = (props) => {

  const canvasRef = useRef(null);
  var terminal;

  function start() {
    game.start();

    var terminalHeight = (game.canvas.width/100)*12;
    var terminalWidth = terminalHeight;

    terminal = new component(
      terminalWidth,
      terminalHeight, 
      "transparent", 
      (game.canvas.width/2)-(terminalWidth/2), 
      (game.canvas.height/3.25)
    );
  }
  
  var game = {
    canvas: canvasRef.current,
    start: function() {
      this.canvas.style.width ='100%';
      this.canvas.style.height='95%';
      this.canvas.width = this.canvas.offsetWidth;
      this.canvas.height = this.canvas.offsetHeight;
      this.context = this.canvas.getContext("2d");
    }
  }

  function component(width, height, color, x, y) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.ctx = game.context;
    this.ctx.fillStyle = color;
    this.ctx.fillRect(this.x, this.y, this.width, this.height);
  }
  
  const draw = ctx => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    //var background = new Image();
    //background.src = computer;
    //background.onload = () => {
    //  ctx.drawImage(background, 0, 0, ctx.canvas.width, ctx.canvas.height);
    //} 
  }

  useEffect(() => {
    game.canvas = canvasRef.current;

    start();
    /* const canvas = canvasRef.current
    canvas.style.width ='100%';
    canvas.style.height='95%';
    // ...then set the internal size to match
    canvas.width  = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const context = canvas.getContext('2d');

    draw(context) */
  }, [start]); //draw])

  return (
    <>
    <canvas ref={canvasRef} {...props}/>
    </>
  );
};

export default Computer;