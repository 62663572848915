import { useState } from 'react';

const PoP = () => {

    const [nfts, setNfts] = useState([
        {
            "title": "CASSINI Hackathons: Space for the Financial World PoP - Participant",
            "image": "https://taikai.infura-ipfs.io/ipfs/QmeFUnVRuhVqvP7tTS7y2yb1zGt8Xnk7ikFdYWKEGPe1GN/pop.png",
            "link": "https://polygonscan.com/nft/0x115cc61a1980295e43f813adec68769c50057088/17"
        },
        {
            "title": "CASSINI Hackathons: Space for Defence & Security PoP - Participant",
            "image": "https://taikai.infura-ipfs.io/ipfs/QmcQpBh2gufGKifzCPvkVwGMoM4Y6mcvKzqa9uGuNsxV5U/pop.png",
            "link": "https://polygonscan.com/nft/0x115cc61a1980295e43f813adec68769c50057088/152"
        },
        {
            "title": "ETH Belgrade Hackathon PoP - Participant",
            "image": "https://taikai.infura-ipfs.io/ipfs/QmRrf8YcANhN2UihTz5B2JjiGYi63L7Zi5yrFhTEd45794/pop.png",
            "link": "https://polygonscan.com/nft/0x115cc61a1980295e43f813adec68769c50057088/361"
        }
    ]);

    return (
        <>
        <div className="row justify-content-center align-items-center flex-grow-1">
            <h2>Our professional participations</h2>
        </div>
        <div className="row justify-content-center flex-grow-1">
        {
            nfts.map((nft, index) => {
                return (
                    <div key={index} className="col-sm-6 col-md-4 col-lg-3 nft-panel">
                        <div className="nft-card">
                            <img src={nft.image} className="card-img-top" alt={nft.title} />
                            <div className="nft-card-body">
                                <h3 className="card-title"><a href={nft.link} rel="noreferrer" target="_blank">{nft.title}</a></h3>
                            </div>
                        </div>
                    </div>
                )
            })
        } 
        </div>  
        </>
    );
}

export default PoP;