import './App.css';

import Info from './components/info.component';
import Computer from './components/computer.component';
import Services from './components/services.component';
import PoP from './components/pop.component';

function App() {

  return (
    <>
    <div className="container-fluid digital-area min-vh-100 d-flex flex-column">
      <div className="row justify-content-center flex-grow-1">
        <div className="col-sm-12 col-md-12 col-lg-6 info-panel">
          <Info />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 computer-panel">
          <Computer />
        </div>
      </div>
    </div>
    <div className="container pop-area min-vh-100 d-flex flex-column">
        <PoP />
    </div>
    {/* <div className="container-fluid services-area min-vh-100 d-flex flex-column">
      <div className="row justify-content-center flex-grow-1">
        <Services />
      </div>
    </div> */}
    </>
  );
}

export default App;
