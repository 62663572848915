import { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";

const emailService = `${process.env.REACT_APP_EMAIL_SERVICE}`;

const ContactModal = ({ handleClose, show }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const defaultFormValues = {goal: '', email: '', message:'' };
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm(
    {defaultValues: defaultFormValues}
  );

  const onSubmit = (data) => { 
    console.log(data);

    sendMail(data);
    
    setFormSubmitted(true);
    reset(defaultFormValues);
  }

  const closeAndReset = () => {


    setFormSubmitted(false);
    setError(false);
    reset(defaultFormValues);
    handleClose();
  } 

  const sendMail = (data) => {

    var submission = {...data};

    axios.post(emailService, submission)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        setError(error.response.data);
      });
  }

  useEffect(() => {

  }, []);  

  return (<>
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="os-panel">
          <div className="os-panel-inner">
            <a href="#" className="close" onClick={() => closeAndReset()}>X</a>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" placeholder="[Your goal in one sentence]" {...register("goal", {required: true, minLength: 10, maxLength: 100})} />
              <input type="text" placeholder="[We need your email to reply]" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
              <textarea rows="4" placeholder="[Details of your goal]" {...register("message", {required: true, minLength: 35, maxLength: 1000})} />
              <input type="submit" value="SEND"/>
              <a className="cancel-btn" onClick={() => closeAndReset()}>CANCEL</a>

              { error ? (
                <div className="os-error" >
                  { error }
                </div>
              ) : (<></>)}

              { errors.goal || errors.email || errors.message ? (
                <div className="os-error" >
                {errors.goal && <><span>Goal is required, 10 characters at least.</span><br/></>}
                {errors.email?.type === 'required' && <><span>Email is required.</span><br/></>}
                {errors.email?.type === 'pattern' && <><span>This is not an email.</span><br/></>}
                {errors.message && <><span>Please phrase your message, 35 characters at least.</span><br/></>}
                </div>
              ) : (<></>) }

              { formSubmitted && !error ? (
                <div className="os-success" >
                  Data sent for hacking successfully!<br/>Please wait for operator answer.<br/>
                  <button className="cancel-btn" onClick={() => closeAndReset()}>CLOSE</button>
                </div>
              ) : (<></>) }
            </form>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default ContactModal;