import { useState, useEffect, useRef } from 'react';
import ContactModal from "./contactModal.component";

const Info = () => {

  const[modal, setShowModal] = useState(false);

  // This ref will be connected to the orange box
  const boxRef = useRef();

  // X
  const [x, setX] = useState(0);

  // Y
  const [y, setY] = useState(0);

  // This function calculate X and Y
  const getPosition = () => {
    const x = boxRef.current.offsetLeft;
    setX(x);

    const y = boxRef.current.offsetTop;
    setY(y);
  };

  // Get the position of the red box in the beginning
  useEffect(() => {
    getPosition();
  }, []);

  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);

  const showModal = () => {
      setShowModal(true);
  };

  const hideModal = () => {
      setShowModal(false);
  };  

  return (
    <>
    <div className="visual-feed">
      <img src="/img/novanet-logo.svg" className="logo" alt="NovaNet | Logo #Ihe6623Dse633ss-" />
      <span className="augmented-name text-center text-lg-start">
        <img src="/img/novanet.svg" className="company-name" alt="NovaNet | #II633h//FeR44" />
      </span>
    </div>
    <div className="augmented-services-container text-center text-lg-start">
      <p>Blockchain services</p>
      <p>AI services</p>
      <p>Satellite data services</p>
    </div>
    <div className="electronic-letter-container text-center text-lg-start" ref={boxRef}>
      <button className="electronic-letter-bitblade" onClick={ () => { showModal() } }>
        <img src="/img/electronic-letter.svg" className="electronic-letter-logo" alt="NovaNet | Contact Us" />
      </button>
    </div>
    <ContactModal show={modal} handleClose={() => hideModal()} />
    </>
  );
};

export default Info;